import request from '@/utils/request'

// 分页查询盘点
export function getRderCheck(params) {
    return request({
        url: '/order/check/page',
        methods: 'get',
        params,
    })
}

// 开始盘点
export function getClothescheck() {
    return request({
        url: '/order/check/start',
        methods: 'get'
    })
}

// 开始盘点
export function continueClothescheck(params) {
    return request({
        url: '/order/check/continue',
        methods: 'get',
        params
    })
}

// 扫码盘点
export function codeCheck(params) {
    return request({
        url: '/order/check/scan/code',
        methods: 'get',
        params
    })
}

// 确认盘点
export function confirmCheck(id, data) {
    return request({
        url: '/order/check/confirm/' + id,
        method: 'post',
        data: data
    })
}

// 扫码完成
export function codeFinished(params) {
    return request({
        url: '/order/check/scan/finished',
        method: 'get',
        params
    })
}

// 待处理异常衣物查询
export function queryHandleClothes(params) {
    return request({
        url: '/order/check/query/handleClothes',
        method: 'get',
        params
    })
}

// 盘点情况说明
export function checkExplain(data) {
    return request({
        url: '/order/check/explain',
        method: 'post',
        data
    })
}

// 盘点完成
export function checkFinished(params) {
    return request({
        url: '/order/check/finished',
        method: 'get',
        params
    })
}

// 盘点详情查询
export function getCheckDetail(params) {
    return request({
        url: '/order/check/detail',
        method: 'get',
        params
    })
}

// 删除盘点
export function delCheck(checkId) {
    return request({
        url: '/order/check/delete/' + checkId,
        method: 'delete',
    })
}


// 分页获取盘点记录
export function getFastCheck(params) {
    return request({
        url: '/order/fast_check/page',
        methods: 'get',
        params,
    })
}

// 扫码盘点
export function codeFastCheck(params) {
    return request({
        url: '/order/fast_check/scan/code',
        methods: 'get',
        params
    })
}



// 新增快速盘点
export function addFastCheck(data) {
    return request({
        url: '/order/fast_check/',
        method: 'post',
        data: data
    })
}

// 衣物详情
export function checkClothesList(checkId) {
    return request({
        url: '/order/fast_check_detail/detail_check_id/' + checkId,
        methods: 'get'
    })
}

// 删除快速盘点
export function delFastCheck(checkId) {
    return request({
        url: '/order/fast_check/' + checkId,
        method: 'delete',
    })
}

// 分页获取盘点记录详情
export function pageFastCheckDetail(params) {
    return request({
        url: '/order/fast_check_detail/page',
        methods: 'get',
        params,
    })
}
