<template>
    <div class="content-index" style="padding:20px; overflow: auto;">
        <div
                class="flex-row"
                style="margin-bottom:10px;cursor:pointer"
                @click="back"
        >
            <i class="el-icon-back" style="font-size:18px;color:#000"></i>
            <span style="padding-left:10px"
            ><span style="color:#8A9099">快速盘点</span> / 详情</span
            >
        </div>
        <div class="drawer-content">
            <el-tabs v-model="formData.recordType" @tab-click="handleClick">
                <el-tab-pane label="全部" name="0">
                    <div
                            v-loading="tableLoading"
                            class="table-box"
                            v-if="formData.anomalyFlag == '0'"
                    >
                        <Table
                                :current-value="formData.current"
                                :header-style="headerStyle"
                                :height="''"
                                :orientation="'center'"
                                :serial-number="true"
                                :table-data="tableData"
                                :title-list="titleListData"
                                :total="total"
                                @handleCurrentChange="handleCurrentChange"
                        />
                    </div>
                </el-tab-pane>
                <el-tab-pane label="门店归属异常" name="1">
                    <div
                            v-loading="tableLoading"
                            class="table-box"
                            v-if="formData.anomalyFlag == '1'"
                    >
                        <Table
                                :current-value="formData.current"
                                :header-style="headerStyle"
                                :height="''"
                                :orientation="'center'"
                                :serial-number="true"
                                :table-data="tableData"
                                :title-list="titleListData"
                                :total="total"
                                @handleCurrentChange="handleCurrentChange"
                        />
                    </div>
                </el-tab-pane>
                <el-tab-pane label="衣物状态异常" name="2">
                    <div
                            v-loading="tableLoading"
                            class="table-box"
                            v-if="formData.anomalyFlag == '2'"
                    >
                        <Table
                                :current-value="formData.current"
                                :header-style="headerStyle"
                                :height="''"
                                :orientation="'center'"
                                :serial-number="true"
                                :table-data="tableData"
                                :title-list="titleListData"
                                :total="total"
                                @handleCurrentChange="handleCurrentChange"
                        />
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div class="table-box"></div>
        </div>
    </div>
</template>

<script>
import Table from "@/components/table2";
import Search from "@/components/search";
import {getInventoryRecordDetailPage} from "@/api/commodity";
import {pageFastCheckDetail} from "@/api/clothes/check";

export default {
    components: {
        Table,
        Search,
    },
    props: {},
    data() {
        return {
            headerStyle: {
                background: "#f5f7fa",
                height: "50px",
            },
            searchData: [
                {
                    prop: "createTime",
                    label: "查询时间",
                    type: "DateTime",
                    labelWidth: "70px",
                },
                {
                    labelWidth: "20px",
                    typeList: [
                        {
                            name: "查询",
                            code: "search",
                            type: "primary",
                        },
                        {
                            name: "重置",
                            code: "reset",
                        },
                    ],
                },
            ],
            formData: {current: 1, size: 10, anomalyFlag: 0},
            total: 0,
            tableData: [],
            titleListData: [
                {
                    prop: "clothesName",
                    label: "衣物名称",
                    width: 170,
                },
                {
                    prop: "outClothesNum",
                    label: "衣物号",
                },
                {
                    prop: "clothesStatusDesc",
                    label: "衣物状态",
                    width: 120,
                },
                {
                    prop: "factoryName",
                    label: "洗涤工厂",
                    width: 100,
                },
                {
                    prop: "storeName",
                    label: "归属门店",
                    width: 100,
                },
                {
                    prop: "storeBelongAnomalyFlagStr",
                    label: "门店归属异常",
                    width: 150,
                },
                {
                    prop: "clothesStatusAnomalyFlagStr",
                    label: "衣物状态异常",
                    width: 170,
                }
            ],
            tableLoading: false,
        };
    },
    created() {
        this.formData.checkId = this.$route.query.checkId;
        this.getInventoryRecordDetailList();
    },
    methods: {
        back() {
            this.$router.back();
        },
        // 出入库明细
        getInventoryRecordDetailList() {
            this.tableLoading = true;
            let data = {
                ...this.formData
            };
            pageFastCheckDetail({...data})
                .then(({data}) => {
                    if (data.code == 0) {
                        this.tableData = data.data.records
                        this.total = data.data.total;
                    } else {
                        this.$message.error(data.msg);
                    }
                    this.tableLoading = false;
                })
                .catch(() => {
                    this.tableLoading = false;
                });
        },
        // 表格分页触发
        handleCurrentChange(value) {
            this.formData.current = value;
            this.getInventoryRecordDetailList();
        },
        // tabs切换触发
        handleClick(e) {
            console.log(e)
            this.formData = {
                current: 1,
                size: 10,
                anomalyFlag: e.name,
                checkId: this.$route.query.checkId,
            };
            this.getInventoryRecordDetailList();
        },
    },
};
</script>

<style lang="scss" scoped>
.drawer-content {
  padding: 0 20px 20px;
}

.flex-row {
  display: flex;
  align-items: center;
}

.box {
  /*强制文本在一行内显示*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep .el-table__body td.el-table__cell {
  height: 50px;
}
</style>
